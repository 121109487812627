// @codekit-prepend "../bower_components/waypoints/lib/jquery.waypoints.min.js";
// @codekit-prepend "../bower_components/waypoints/lib/shortcuts/inview.js";
// @codekit-prepend "../bower_components/magnific-popup/dist/jquery.magnific-popup.min.js";
// @codekit-prepend "../bower_components/slick-carousel/slick/slick.min.js";
// @codekit-prepend "../bower_components/matchHeight/jquery.matchHeight.js";



$(document).foundation();




$(document).ready(function(){

  $('.modul--bossladys-slider').slick({
    arrows: true,
    prevArrow: '<button type="button" class="slick-prev"><img src="../img/arrowright.svg" /></button>',
    nextArrow: '<button type="button" class="slick-next"><img src="../img/arrowleft.svg" /></button>',
  });

  $(".member").click(function(e) {
    e.preventDefault();
    $(".anime--skew-member").toggleClass("active");
  });

  $('.box-kp, .box-pu').matchHeight({
    property: "min-height"
  });

  $('.open-popup-link').magnificPopup({
    type:'inline'
  });

  $('.anmelden').magnificPopup({
    type: 'iframe',
    closeOnBgClick: false,
		removalDelay: 100
  });

  $(".modul--pakete span").click(function() {
    $(this).parent().toggleClass("act");
    $(this).next().slideToggle();
  });

  $("a.scroll").click(function(e) {
    e.preventDefault();

    var scrollto = $(this).attr("href");

    $("div.menu span").toggleClass("active");
    $("div.anime--skew-menu").toggleClass("active");

    $('html, body').animate({
        scrollTop: $(scrollto).offset().top
    }, 1000);

  });

  $("div.menu span").click(function() {
    $("div.menu span").toggleClass("active");
    $("div.anime--skew-menu").toggleClass("active");
  });


  $(".anime--skew").each(function() {
    var elem = $(this);
    var inview = new Waypoint.Inview({
      element: elem[0],
      enter: function(direction) {
        setTimeout(function(){
          elem.addClass("active");
        }, 500);

      }
    })
  });




});
